
























import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component({
  layout: 'unAuthenticated',
})
export default class PasswordReset extends Vue {
  password = ''
  confirmPassword = ''

  submit(e) {
    e.preventDefault()
    const pwd = this.password
    const confirmPwd = this.confirmPassword
    if (pwd.length > 0 && pwd === confirmPwd) {
      this.$axios
        .post('/v3/user/reset_password', {
          password: pwd,
          token: this.$route.params.token,
        })
        .then((response) => {
          vxm.alert.success({
            content: this.$t('Your password has been changed, you can now login with you new password') as string,
          })
          this.$router.push('/login')
        })
    } else {
      vxm.alert.warning({
        content: this.$t('You have ether not entered a password or the password aren\'t matching') as string,
      })
    }
  }
}
